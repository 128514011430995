// src/utils/getPaybisUrl.js
import { set_iframe_popup } from "../features/iframe/iframeSlice";
import { fetchData } from "./fetchData";
import { endpoints } from "./fetchUrls";

export async function getPaybisUrl(wallet_address, userMagicAddress, dispatch) {
  if (!wallet_address) return;

  // Paybis configuration
  const paybisUrl = "https://widget.paybis.com";
  const paybisPartnerId = "d6840fd3-4602-41d0-82b5-d56e2302da2d";
  const currencyCodeTo = "USDT";
  const paybisDefaultAmount = 100;

  // getting wallet address where money for swap should be sent to
  let nexusCurrencyIdUSDTTRC20 = "USDT-TRC20";
  const fetchDataUrl = `${endpoints.getGlobalWalletForUser}/${userMagicAddress}/${nexusCurrencyIdUSDTTRC20}`;
  const swapAddress = await fetchData(fetchDataUrl);
  console.log("swapAddress.data: ", swapAddress.data);

  if (!swapAddress.data) return;

  let buyUrl = `${paybisUrl}/?partnerId=${paybisPartnerId}&cryptoAddress=${swapAddress.data}&currencyCodeFrom=USD&currencyCodeTo=${currencyCodeTo}&amountFrom=${paybisDefaultAmount}`;
  console.log("buyUrl: ", buyUrl);

  return dispatch(set_iframe_popup({ src: buyUrl, type: "paybis" }));
}
